import Vue from "vue";
import VueRouter from "vue-router";
import { trainingInstitutionAccess } from "@/plugins/accessAuthority";
import MainBody from "../views/mainBody.vue";
import HomePage from "../views/homePage/homePage.vue";
import NotFound from "../views/NotFound/NotFound.vue";
import cannotAccess from "../views/cannotAccess/cannotAccess.vue";

// 用户管理
import DomesticConsumer from "../views/userManagement/domesticConsumer.vue";
import AdminManagement from "../views/userManagement/adminManagement.vue";
import platformManagement from "../views/userManagement/platformLevelAdmin/platformManagement.vue";
import platformNationalManagement from "../views/userManagement/platformLevelAdmin/platformNationalManagement.vue";
import platformProviceManagement from "../views/userManagement/platformLevelAdmin/platformProviceManagement.vue";
import platformCityManagement from "../views/userManagement/platformLevelAdmin/platformCityManagement.vue";
import platformDistrictManagement from "../views/userManagement/platformLevelAdmin/platformDistrictManagement.vue";
import platformStreetManagement from "../views/userManagement/platformLevelAdmin/platformStreetManagement.vue";
// 添加各级管理员
import addPlatFormAdmin from "../views/userManagement/addtionAdmin/addPlatFormAdmin.vue";
import addNationalAdmin from "../views/userManagement/addtionAdmin/addNationalAdmin.vue";
import addProviceAdmin from "../views/userManagement/addtionAdmin/addProviceAdmin.vue";
import addCityAdmin from "../views/userManagement/addtionAdmin/addCityAdmin.vue";
import addDistrictAdmin from "../views/userManagement/addtionAdmin/addDistrictAdmin.vue";
import addStreetAdmin from "../views/userManagement/addtionAdmin/addStreetAdmin.vue";
// 编辑各级管理员
import editPlatFormAdmin from "../views/userManagement/editAdmin/editPlatFormAdmin.vue";
import editNationalAdmin from "../views/userManagement/editAdmin/editNationalAdmin.vue";
import editProviceAdmin from "../views/userManagement/editAdmin/editProviceAdmin.vue";
import editCityAdmin from "../views/userManagement/editAdmin/editCityAdmin.vue";
import editDistrictAdmin from "../views/userManagement/editAdmin/editDistrictAdmin.vue";
import editStreetAdmin from "../views/userManagement/editAdmin/editStreetAdmin.vue";
import updateAdmin from "../views/userManagement/updateAdmin.vue";

// 单位
import UnitManagement from "../views/unitManagement/unitManagement.vue";
import MemberManagement from "../views/unitManagement/memberManagement.vue";
import JobManagement from "../views/unitManagement/jobManagement.vue";
import UnitAuthentication from "../views/authentication/unitAuthentication.vue";

// 课程类别
import ClassesManagement from "../views/courseManagement/category/classesManagement.vue";
import NewClasses from "../views/courseManagement/category/newClasses.vue";
import EditClasses from "../views/courseManagement/category/editClasses.vue";

// 课程
import trainingInstitutionCourseList from "../views/courseManagement/course/trainingInstitutionCourseList.vue";
import CheckStudyData from "../views/courseManagement/study/checkStudyData.vue";
import SpecialColumn from "../views/specialTopicManagement/specialColumn.vue";
import SpecialTopic from "../views/specialTopicManagement/specialTopic.vue";

// 登录注册
import UserLogin from "../views/userLogin.vue";
import UserRegister from "../views/userRegister.vue";
import RetrievePassword from "../views/retrievePassword.vue";

// 培训设置
import TrainingSetupList from "../views/trainingManagement/trainingSetup/trainingSetupList.vue";
import NewTraining from "../views/trainingManagement/trainingSetup/newTraining.vue";
import EditTraining from "../views/trainingManagement/trainingSetup/editTraining.vue";
import getCertificateCondition from "../views/trainingManagement/trainingSetup/getCertificateCondition.vue";
import MoreTrainingUnit from "../views/trainingManagement/trainingSetup/checkMore/moreTrainingUnit.vue";
import MoreTrainingCourse from "../views/trainingManagement/trainingSetup/checkMore/moreTrainingCourse.vue";

// 考试列表
import ExaminationList from "../views/trainingManagement/examination/examinationList.vue";

// 培训情况查询
import TrainingInquiryList from "../views/trainingManagement/trainingInquiry/trainingInquiryList.vue";
import CheckTrainingData from "../views/trainingManagement/trainingInquiry/checkTrainingData.vue";
import CheckUnitData from "../views/trainingManagement/trainingInquiry/checkUnitData.vue";
import CheckUnitUserData from "../views/trainingManagement/trainingInquiry/checkUnitUserData.vue";
import CheckStudyBehaviorRecord from "../views/trainingManagement/trainingInquiry/checkStudyBehaviorRecord.vue";

// 发票审核
import InvoiceReviewList from "../views/InvoiceReviewManagement/invoiceReviewList.vue";
import ReviewDetail from "../views/InvoiceReviewManagement/reviewDetail.vue";

// 证书模板管理
import certificateManagementList from "../views/certificateManagement/certificateManagementList.vue";
import newCertificate from "../views/certificateManagement/newCertificate.vue";
import editCertificate from "../views/certificateManagement/editCertificate.vue";

// 轮播图管理
import slideshowList from "../views/slideshowManagement/slideshowList.vue";
import newSlideshow from "../views/slideshowManagement/newSlideshow.vue";
import editSlideshow from "../views/slideshowManagement/editSlideshow.vue";

// 公告管理
import announcementList from "../views/announcementManagement/announcementList.vue";
import newAnnouncement from "../views/announcementManagement/newAnnouncement.vue";
import editAnnouncement from "../views/announcementManagement/editAnnouncement.vue";

// 用户协议管理
import userAgreementList from "../views/userAgreementManagement/userAgreementList.vue";
import newAgreement from "../views/userAgreementManagement/newAgreement.vue";
import editAgreement from "../views/userAgreementManagement/editAgreement.vue";

// 隐私政策
import privacyPolicyList from "../views/privacyPolicyManagement/privacyPolicyList.vue";
import newPrivacyPolicy from "../views/privacyPolicyManagement/newPrivacyPolicy.vue";
import editPrivacyPolicy from "../views/privacyPolicyManagement/editPrivacyPolicy.vue";

//岗位管理  PositionManagement
import PositionManagement from "../views/userAgreementManagement/PositionManagement.vue";

// 帮助文档
import helpDocumentList from "../views/helpDocumentManagement/helpDocumentList.vue";
import newHelpDocument from "../views/helpDocumentManagement/newHelpDocument.vue";
import editHelpDocument from "../views/helpDocumentManagement/editHelpDocument.vue";

// 帮助文档分组管理
import helpGocumentGroupList from "../views/helpDocumentManagement/helpGocumentGroupList.vue";

// 反馈管理
import feedbackList from "../views/feedback/feedbackList.vue";

// 退款密码设置
import refundPasswordSetting from "../views/refundPasswordSetting/refundPasswordSetting.vue";

// 政府部门
import governmentSectorList from "../views/governmentSectorManagement/governmentSectorList.vue";
import newGovernmentSector from "../views/governmentSectorManagement/newGovernmentSector.vue";
import editGovernmentSector from "../views/governmentSectorManagement/editGovernmentSector.vue";
// 政府部门成员管理
import governmentMemberList from "../views/governmentSectorManagement/governmentMemberList.vue";
import newGovernmentMember from "../views/governmentSectorManagement/newGovernmentMember.vue";
import editGovernmentMember from "../views/governmentSectorManagement/editGovernmentMember.vue";
import updateGovernmentMemberRole from "../views/governmentSectorManagement/updateGovernmentMemberRole.vue";

// 培训机构
import trainingInstitutionList from "../views/trainingInstitutionManagement/trainingInstitutionList.vue";
import newTrainingInstitution from "../views/trainingInstitutionManagement/newTrainingInstitution.vue";
import editTrainingInstitution from "../views/trainingInstitutionManagement/editTrainingInstitution.vue";
// 培训机构成员管理
import trainingInstitutionMemberList from "../views/trainingInstitutionManagement/trainingInstitutionMemberList.vue";
import newTrainingInstitutionMember from "../views/trainingInstitutionManagement/newTrainingInstitutionMember.vue";
import editTrainingInstitutionMember from "../views/trainingInstitutionManagement/editTrainingInstitutionMember.vue";

// 培训机构管理-设置可查看的课程
import AvailableCoursesList from "../views/trainingInstitutionManagement/AvailableCoursesList.vue";

// 兑换码管理
import redeemCodeList from "../views/redeemCodeManagement/redeemCodeList.vue";
import newRedeemCode from "../views/redeemCodeManagement/newRedeemCode.vue";

// 单位支付套餐管理
import unitPayPackageList from "../views/unitPayPackageManagement/unitPayPackageList.vue";

// 查看学生
import viewStudents from "../views/viewStudents/viewStudents.vue";
// 退款记录
import refundRecord from "../views/viewStudents/refundRecord.vue";
//运维管理导出订单
import orderForGoods from "../views/operationManagement/orderForGoods.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/login", name: "登录", component: UserLogin },
  { path: "/register", name: "注册", component: UserRegister },
  { path: "/retrieve", name: "忘记密码", component: RetrievePassword },
  { path: "/NotFound", name: "404", component: NotFound },
  { path: "/cannotAccess", name: "cannotAccess", component: cannotAccess },
  {
    path: "/",
    component: MainBody,
    redirect: "/homePage",
    children: [
      {
        path: "/homePage",
        component: HomePage,
        name: "首页",
        meta: {
          requireAuth: true,
        },
      }, //首页
      {
        path: "/domesticConsumer",
        component: DomesticConsumer,
        name: "普通用户管理",
        meta: {
          requireAuth: true,
        },
      }, //普通用户管理
      {
        path: "/adminManagement",
        component: AdminManagement,
        name: "管理员管理",
        meta: {
          requireAuth: true,
        },
      }, //管理员管理
      {
        path: "/updateAdmin",
        component: updateAdmin,
        name: "更改管理员角色",
        meta: {
          requireAuth: true,
          breadNumber: 2, // 二级路由
        },
      }, //更改管理员角色
      {
        path: "/unitManagement",
        component: UnitManagement,
        name: "单位管理",
        meta: {
          requireAuth: true,
        },
      }, //单位管理
      {
        path: "/memberManagement",
        component: MemberManagement,
        name: "成员管理",
        meta: {
          requireAuth: true,
          breadNumber: 2, // 二级路由
        },
      }, //成员管理
      {
        path: "/jobManagement",
        component: JobManagement,
        name: "职位管理",
        meta: {
          requireAuth: true,
          breadNumber: 2, // 二级路由
        },
      }, //职位管理
      {
        path: "/unitAuthentication",
        component: UnitAuthentication,
        name: "单位认证",
        meta: {
          requireAuth: true,
        },
      }, //单位认证
      {
        path: "/classesManagement",
        component: ClassesManagement,
        name: "课程类别管理",
        meta: {
          requireAuth: true,
        },
      }, //课程类别管理
      {
        path: "/newClasses",
        component: NewClasses,
        name: "新建课程类别",
        meta: {
          requireAuth: true,
          breadNumber: 2, // 课程类别新建
        },
      }, //课程类别新建
      {
        path: "/editClasses",
        component: EditClasses,
        name: "编辑课程类别",
        meta: {
          requireAuth: true,
          breadNumber: 2, // 课程类别编辑
        },
      }, //课程类别编辑
      {
        path: "/trainingInstitutionCourseList",
        component: trainingInstitutionCourseList,
        name: "课程管理",
        meta: {
          requireAuth: true,
        },
      }, //课程管理
      {
        path: "/specialColumn",
        component: SpecialColumn,
        name: "专栏管理",
        meta: {
          requireAuth: true,
        },
      }, //专栏管理
      {
        path: "/specialTopic",
        component: SpecialTopic,
        name: "专题管理",
        meta: {
          requireAuth: true,
        },
      }, //专题管理
      {
        path: "/checkStudyData",
        component: CheckStudyData,
        name: "查看学习数据",
        meta: {
          requireAuth: true,
        },
      }, //查看学习数据
      {
        path: "/trainingSetupList",
        component: TrainingSetupList,
        name: "培训设置",
        meta: {
          requireAuth: true,
        },
      }, //培训设置
      {
        path: "/newTraining",
        component: NewTraining,
        name: "创建培训",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //创建培训
      {
        path: "/getCertificateCondition",
        component: getCertificateCondition,
        name: "证书获取情况",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //证书获取情况
      {
        path: "/editTraining",
        component: EditTraining,
        name: "编辑培训",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //编辑培训
      {
        path: "/moreTrainingUnit",
        component: MoreTrainingUnit,
        name: "参与培训的单位",
        meta: {
          requireAuth: true,
          breadNumber: 3,
        },
      }, //参与培训的单位
      {
        path: "/moreTrainingCourse",
        component: MoreTrainingCourse,
        name: "培训课程",
        meta: {
          requireAuth: true,
          breadNumber: 3,
        },
      }, //培训课程
      {
        path: "/trainingInquiryList",
        component: TrainingInquiryList,
        name: "培训情况查询",
        meta: {
          requireAuth: true,
        },
      }, //培训情况查询
      {
        path: "/checkTrainingData",
        component: CheckTrainingData,
        name: "查看培训数据",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //查看培训数据
      {
        path: "/checkUnitData",
        component: CheckUnitData,
        name: "查看单位数据",
        meta: {
          requireAuth: true,
          breadNumber: 3,
        },
      }, //查看单位数据
      {
        path: "/checkUnitUserData",
        component: CheckUnitUserData,
        name: "查看单位用户数据",
        meta: {
          requireAuth: true,
          breadNumber: 3,
        },
      }, //查看单位用户数据
      {
        path: "/checkStudyBehaviorRecord",
        component: CheckStudyBehaviorRecord,
        name: "查看学习行为记录",
        meta: {
          requireAuth: true,
          breadNumber: 4,
        },
      }, //查看学习行为记录
      {
        path: "/examinationList",
        component: ExaminationList,
        name: "考试列表",
        meta: {
          requireAuth: true,
        },
      }, //考试列表
      {
        path: "/invoiceReviewList",
        component: InvoiceReviewList,
        name: "发票审核",
        meta: {
          requireAuth: true,
        },
      }, //发票审核
      {
        path: "/reviewDetail",
        component: ReviewDetail,
        name: "审核详情",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //审核详情
      {
        path: "/platformNationalManagement",
        component: platformNationalManagement,
        name: "国家级管理员管理",
        meta: {
          requireAuth: true,
        },
      }, //平台国家级管理员管理
      {
        path: "/platformManagement",
        component: platformManagement,
        name: "平台管理员管理",
        meta: {
          requireAuth: true,
        },
      }, //平台管理员管理
      {
        path: "/platformProviceManagement",
        component: platformProviceManagement,
        name: "省级管理员管理",
        meta: {
          requireAuth: true,
        },
      }, //省级管理员管理
      {
        path: "/platformCityManagement",
        component: platformCityManagement,
        name: "市级管理员管理",
        meta: {
          requireAuth: true,
        },
      }, //市级管理员管理
      {
        path: "/platformDistrictManagement",
        component: platformDistrictManagement,
        name: "区级管理员管理",
        meta: {
          requireAuth: true,
        },
      }, //区级管理员管理
      {
        path: "/platformStreetManagement",
        component: platformStreetManagement,
        name: "街道级管理员管理",
        meta: {
          requireAuth: true,
        },
      }, //街道级管理员管理
      {
        path: "/addPlatFormAdmin",
        component: addPlatFormAdmin,
        name: "添加平台管理员",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //添加平台管理员
      {
        path: "/editPlatFormAdmin",
        component: editPlatFormAdmin,
        name: "编辑平台管理员",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //编辑平台管理员
      {
        path: "/addNationalAdmin",
        component: addNationalAdmin,
        name: "添加国家级管理员",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //添加国家级管理员
      {
        path: "/editNationalAdmin",
        component: editNationalAdmin,
        name: "编辑国家级管理员",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //编辑国家级管理员
      {
        path: "/addProviceAdmin",
        component: addProviceAdmin,
        name: "添加省级管理员",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //添加省级管理员
      {
        path: "/editProviceAdmin",
        component: editProviceAdmin,
        name: "编辑省级管理员",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //编辑省级管理员
      {
        path: "/addCityAdmin",
        component: addCityAdmin,
        name: "添加市级管理员",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //添加市级管理员
      {
        path: "/editCityAdmin",
        component: editCityAdmin,
        name: "编辑市级管理员",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //编辑市级管理员
      {
        path: "/addDistrictAdmin",
        component: addDistrictAdmin,
        name: "添加区（县）级管理员",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //添加区（县）级管理员
      {
        path: "/editDistrictAdmin",
        component: editDistrictAdmin,
        name: "编辑区（县）级管理员",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //编辑区（县）级管理员
      {
        path: "/addStreetAdmin",
        component: addStreetAdmin,
        name: "添加街道级管理员",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //添加街道级管理员
      {
        path: "/editStreetAdmin",
        component: editStreetAdmin,
        name: "编辑街道级管理员",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //编辑街道级管理员
      {
        path: "/certificateManagementList",
        component: certificateManagementList,
        name: "证书管理",
        meta: {
          requireAuth: true,
        },
      }, //证书管理
      {
        path: "/newCertificate",
        component: newCertificate,
        name: "创建证书模板",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //创建证书模板
      {
        path: "/editCertificate",
        component: editCertificate,
        name: "编辑证书模板",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //编辑证书模板
      {
        path: "/slideshowList",
        component: slideshowList,
        name: "轮播图管理",
        meta: {
          requireAuth: true,
        },
      }, //轮播图管理
      {
        path: "/newSlideshow",
        component: newSlideshow,
        name: "新建轮播图",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //新建轮播图
      {
        path: "/editSlideshow",
        component: editSlideshow,
        name: "编辑轮播图",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //编辑轮播图
      {
        path: "/announcementList",
        component: announcementList,
        name: "公告管理",
        meta: {
          requireAuth: true,
        },
      }, //公告管理
      {
        path: "/newAnnouncement",
        component: newAnnouncement,
        name: "新建公告",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //新建公告
      {
        path: "/editAnnouncement",
        component: editAnnouncement,
        name: "编辑公告",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //编辑公告
      {
        path: "/PositionManagement",
        component: PositionManagement,
        name: "岗位管理",
        meta: {
          requireAuth: true,
        },
      }, //岗位管理
      {
        path: "/userAgreementList",
        component: userAgreementList,
        name: "用户协议管理",
        meta: {
          requireAuth: true,
        },
      }, //用户协议管理
      {
        path: "/newAgreement",
        component: newAgreement,
        name: "新建用户协议",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //新建用户协议
      {
        path: "/editAgreement",
        component: editAgreement,
        name: "编辑用户协议",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //编辑用户协议
      {
        path: "/privacyPolicyList",
        component: privacyPolicyList,
        name: "隐私政策管理",
        meta: {
          requireAuth: true,
        },
      }, //隐私政策管理
      {
        path: "/newPrivacyPolicy",
        component: newPrivacyPolicy,
        name: "新建隐私政策",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //新建隐私政策
      {
        path: "/editPrivacyPolicy",
        component: editPrivacyPolicy,
        name: "编辑隐私政策",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //编辑隐私政策
      {
        path: "/helpDocumentList",
        component: helpDocumentList,
        name: "帮助文档管理",
        meta: {
          requireAuth: true,
        },
      }, //帮助文档管理
      {
        path: "/newHelpDocument",
        component: newHelpDocument,
        name: "新建帮助文档",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //新建帮助文档
      {
        path: "/editHelpDocument",
        component: editHelpDocument,
        name: "编辑帮助文档",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //编辑帮助文档
      {
        path: "/helpGocumentGroupList",
        component: helpGocumentGroupList,
        name: "帮助文档分组列表",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //帮助文档分组列表
      {
        path: "/feedbackList",
        component: feedbackList,
        name: "反馈管理",
        meta: {
          requireAuth: true,
        },
      }, //反馈管理
      {
        path: "/refundPasswordSetting",
        component: refundPasswordSetting,
        name: "退款密码设置",
        meta: {
          requireAuth: true,
        },
      }, //退款密码设置
      {
        path: "/governmentSectorList",
        component: governmentSectorList,
        name: "政府部门管理",
        meta: {
          requireAuth: true,
        },
      }, //政府部门管理
      {
        path: "/newGovernmentSector",
        component: newGovernmentSector,
        name: "新建政府部门",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //新建政府部门
      {
        path: "/editGovernmentSector",
        component: editGovernmentSector,
        name: "编辑政府部门",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //编辑政府部门
      {
        path: "/governmentMemberList",
        component: governmentMemberList,
        name: "政府部门成员管理",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //政府部门成员管理
      {
        path: "/newGovernmentMember",
        component: newGovernmentMember,
        name: "添加政府部门成员",
        meta: {
          requireAuth: true,
          breadNumber: 3,
        },
      }, //添加政府部门成员
      {
        path: "/editGovernmentMember",
        component: editGovernmentMember,
        name: "修改政府部门成员",
        meta: {
          requireAuth: true,
          breadNumber: 3,
        },
      }, //修改政府部门成员
      {
        path: "/updateGovernmentMemberRole",
        component: updateGovernmentMemberRole,
        name: "更改政府部门管理员角色",
        meta: {
          requireAuth: true,
          breadNumber: 3,
        },
      }, //更改政府部门管理员角色
      {
        path: "/trainingInstitutionList",
        component: trainingInstitutionList,
        name: "培训机构管理",
        meta: {
          requireAuth: true,
        },
      }, //培训机构管理
      {
        path: "/newTrainingInstitution",
        component: newTrainingInstitution,
        name: "新建培训机构",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //新建培训机构
      {
        path: "/editTrainingInstitution",
        component: editTrainingInstitution,
        name: "编辑培训机构",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //编辑培训机构
      {
        path: "/trainingInstitutionMemberList",
        component: trainingInstitutionMemberList,
        name: "培训机构成员管理",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //培训机构成员管理
      {
        path: "/AvailableCoursesList",
        component: AvailableCoursesList,
        name: "设置可查看的课程",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //设置可查看课程
      {
        path: "/newTrainingInstitutionMember",
        component: newTrainingInstitutionMember,
        name: "新建培训机构成员",
        meta: {
          requireAuth: true,
          breadNumber: 3,
        },
      }, //新建培训机构成员
      {
        path: "/editTrainingInstitutionMember",
        component: editTrainingInstitutionMember,
        name: "编辑培训机构成员",
        meta: {
          requireAuth: true,
          breadNumber: 3,
        },
      }, //编辑培训机构成员
      {
        path: "/redeemCodeList",
        component: redeemCodeList,
        name: "兑换码管理",
        meta: {
          requireAuth: true,
        },
      }, //兑换码管理
      {
        path: "/newRedeemCode",
        component: newRedeemCode,
        name: "新建兑换码",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //新建兑换码
      {
        path: "/unitPayPackageList",
        component: unitPayPackageList,
        name: "单位支付套餐管理",
        meta: {
          requireAuth: true,
        },
      }, //单位支付套餐管理
      {
        path: "/viewStudents",
        component: viewStudents,
        name: "查看学生",
        meta: {
          requireAuth: true,
          breadNumber: 2,
        },
      }, //查看学生
      {
        path: "/refundRecord",
        component: refundRecord,
        name: "退款记录",
        meta: {
          requireAuth: true,
          breadNumber: 3,
        },
      }, //退款记录
      {
        path: "/orderForGoods",
        component: orderForGoods,
        name: "导出订单",
        meta: {
          requireAuth: true,
          breadNumber: 1,
        },
      }, //导出订单
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem("token");
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  if (sessionStorage.getItem("umeetCloudVuex")) {
    let umeetCloudVuex = JSON.parse(sessionStorage.getItem("umeetCloudVuex"));
    const newBread = { name: to.name, path: to.fullPath };
    const vuexBreadList = []; // 默认初始化面包屑数据
    // 判断是否为师堂跳转过来路径，条件为true 重置面包屑
    if (to.path === "/redeemCodeList" || to.path === "/unitPayPackageList") {
      vuexBreadList.push(newBread);
      umeetCloudVuex.breadListState = vuexBreadList;
      sessionStorage.setItem("umeetCloudVuex", JSON.stringify(umeetCloudVuex));
      next();
    }
  }
  if (userInfo) {
    if (userInfo.flag === 1 && trainingInstitutionAccess(to.path)) {
      next("/cannotAccess");
    }
  }

  if (!token && to.path === "/login") {
    next();
  } else if (!token && to.path !== "/login") {
    next("/login");
  } else if (!to.name) {
    next("/NotFound");
  }
  next();
});
export default router;
