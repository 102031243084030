import axios from "../plugins/axios";

// 培训机构管理=======
// 培训机构列表
export const getEducationTrainingInstitutionsList = (params) =>
  axios.get(
    "/course/educationtraininginstitutions/getEducationTrainingInstitutionsList?name=" +
      params.name +
      "&status=" +
      params.status +
      "&startNum=" +
      params.startNum +
      "&pageSize=" +
      params.pageSize +
      "&provinceId=" +
      params.provinceId +
      "&cityId=" +
      params.cityId +
      "&regionId=" +
      params.regionId +
      "&streetId=" +
      params.streetId
  );

/**
 * 新增培训机构
 * @param {*} param
 * name//名称
 * provinceId//省
 * cityId//市
 * regionId//区
 * streetId//街道
 * @returns
 */
export const saveTraininginstitutions = (param) =>
  axios.post(
    "/course/educationtraininginstitutions/saveTraininginstitutions",
    param
  );

/**
 * 修改培训机构
 * @param {*} param
 * id-培训机构id
 * name//名称
 * provinceId//省
 * cityId//市
 * regionId//区
 * streetId//街道
 * @returns
 */
export const updateTraininginstitutions = (param) =>
  axios.post(
    "/course/educationtraininginstitutions/updateTraininginstitutions",
    param
  );

// 删除培训机构
export const deleteTraininginstitutions = (param) =>
  axios.get(
    "/course/educationtraininginstitutions/deleteTraininginstitutions?id=" +
      param.id
  );

// 查看详情培训机构
export const selectTraininginstitutions = (param) =>
  axios.get(
    "/course/educationtraininginstitutions/selectTraininginstitutions?id=" +
      param.id
  );

/**
 * 培训机构启用/禁用
 * @param {*} param
 * id-培训机构id
 * recovery true-禁用 false-启用
 * @returns
 */
export const updateTraininginstitutionsStatus = (param) =>
  axios.post(
    "/course/educationtraininginstitutions/updateTraininginstitutionsStatus",
    param
  );

// 培训机构成员管理=======
/**
 * 获取培训机构成员列表
 * @param {name,status,startNum,pageSize} param
 * id-培训机构id
 * name-关键字
 * status-状态 true-禁用 false-启用
 * startNum-页码
 * pageSize-每页条数
 * @returns
 */
export const getEducationTrainingInstitutionsUserList = (param) =>
  axios.get(
    "/course/educationtraininginstitutions/getEducationTrainingInstitutionsUserList?name=" +
      param.name +
      "&id=" +
      param.id +
      "&status=" +
      param.status +
      "&startNum=" +
      param.startNum +
      "&pageSize=" +
      param.pageSize
  );

/**
 * 新增培训机构成员
 * @param {*} param
 * account//账号
 * name//名称
 * phone//手机
 * password//密码
 * confirmPassword//确认密码
 * departmentId//培训机构id
 * @returns
 */
export const saveTrainingInstitutionsUser = (param) =>
  axios.post(
    "/course/educationtraininginstitutions/saveTrainingInstitutionsUser",
    param
  );

/**
 * 修改培训机构成员
 * @param {*} param
 * id-培训机构成员id
 * account//账号
 * name//名称
 * phone//手机
 * password//密码
 * confirmPassword//确认密码
 * departmentId//培训机构id
 * @returns
 */
export const updateTrainingInstitutionsUser = (param) =>
  axios.post(
    "/course/educationtraininginstitutions/updateTrainingInstitutionsUser",
    param
  );

// 删除培训机构成员
export const deleteTrainingInstitutionsUser = (param) =>
  axios.get(
    "/course/educationtraininginstitutions/deleteTrainingInstitutionsUser?id=" +
      param.id
  );

// 查看详情培训机构成员
export const selectTrainingInstitutionsUser = (param) =>
  axios.get(
    "/course/educationtraininginstitutions/selectTrainingInstitutionsUser?id=" +
      param.id
  );

/**
 * 培训机构成员启用/禁用
 * @param {*} param
 * id-培训机构成员id
 * recovery true-禁用 false-启用
 * @returns
 */
export const updateTrainingInstitutionsUserStatus = (param) =>
  axios.post(
    "/course/educationtraininginstitutions/updateTrainingInstitutionsUserStatus",
    param
  );

/**
 * 培训机构成员设为主管理员
 * @param {*} param
 * id-培训机构成员id
 * @returns
 */
export const updateIsAdmin = (param) =>
  axios.get(
    "/course/educationtraininginstitutions/updateIsAdmin?id=" + param.id
  );

// 查看已选课程
export const getTrainingInstitutionsLessonList = (param) =>
  axios.get(
    "/course/educationtraininginstitutions/getTrainingInstitutionsLessonList?trainingInstitutionId=" +
      param.trainingInstitutionId
  );
// 保存课程列表
export const saveButton = (param) =>
  axios.post(
    "/course/educationtraininginstitutions/saveTrainingInstitutionsLesson",
    param
  );
