import axios from "../plugins/axios";
// 培训管理
// 培训设置================

// 培训设置列表
export const platformTrainingList = (param) =>
  axios.get(
    "/course/platformTraining/getList?name=" +
      param.name +
      "&status=" +
      param.status +
      "&begintime=" +
      param.begintime +
      "&endtime=" +
      param.endtime +
      "&provinceId=" +
      param.provinceId +
      "&cityId=" +
      param.cityId +
      "&regionId=" +
      param.regionId +
      "&streetId=" +
      param.streetId +
      "&pages=" +
      param.pages +
      "&startNum=" +
      param.startNum
  );
// 培训设置列表 地区详情
export const getAreaList = (param) =>
  axios.get(
    "/course/platformTraining/getAreaList?traingId=" +
      param.id +
      "&pageSize=" +
      param.pageSize +
      "&startNum=" +
      param.startNum
  );
// 培训设置列表 公司详情
export const getCompanyList = (param) =>
  axios.get("/course/platformTraining/getCompanyList?traingId=" + param.id);
// 培训设置列表 岗位详情
export const getPositionList = (param) =>
  axios.get("/course/platformTraining/getPositionList?traingId=" + param.id);

//岗位列表
export const getAddPlatformPositionList = () => {
  return axios({
    url: "/course/platformTraining/getAddPlatformPositionList",
    method: "get",
    isLoading: false,
  });
};

//单位列表
export const getAddCompanyList = (param) =>
  axios.post("/api/company/getAddCompanyList", param);
export const getAddLessonList1 = (param) =>
  axios.get(
    "/course/platformTraining/getAddLessonList1?name=" +
      param.name +
      "&startNum=" +
      param.startNum +
      "&pageSize=" +
      param.pageSize
  );

//培训设置课程列表
export const getAddTrainingList = () => {
  return axios({
    url: "/course/platformTraining/getAddTrainingList",
    method: "get",
    isLoading: false,
  });
};
// axios.get("/course/platformTraining/getAddTrainingList", {
//   isLoading: false,
// });

//课程列表
export const getAddLessonList = (param) => {
  return axios({
    url: "/course/platformTraining/getAddLessonList?traingId=" + param.traingId,
    method: "get",
    isLoading: false,
  });
};
// 新建培训
export const savePlatformTraining = (param) =>
  axios.post("/course/platformTraining/savePlatformTraining", param);

// 培训详情
export const platformTrainingDetail = (param) => {
  return axios({
    url: "/course/platformTraining/detail?id=" + param.id,
    method: "get",
    isLoading: false,
  });
};
// axios.get("/course/platformTraining/detail?id=" + param.id);

// 删除培训
export const deletePlatformTraining = (param) =>
  axios.get(
    "/course/platformTraining/deletePlatformTraining?traingId=" + param.id
  );

// 修改培训
export const updatePlatformTraining = (param) =>
  axios.post("/course/platformTraining/updatePlatformTraining", param);

// 培训查询情况============

// 查看培训数据
export const getPlatformTrainingDataList = (param) =>
  axios.get(
    "/course/platformTraining/getPlatformTrainingDataList?traingId=" +
      param.id +
      "&name=" +
      param.name +
      "&positionId=" +
      param.positionId +
      "&companyId=" +
      param.companyId +
      "&lessonId=" +
      param.lessonId +
      "&provinceId=" +
      param.provinceId +
      "&cityId=" +
      param.cityId +
      "&regionId=" +
      param.regionId +
      "&streetId=" +
      param.streetId +
      "&pageSize=" +
      param.pageSize +
      "&startNum=" +
      param.startNum
  );

// 查看单位用户数据
export const getPlatformTrainingCompanyDataList = (param) =>
  axios.get(
    "/course/platformTraining/getPlatformTrainingCompanyDataList?companyId=" +
      param.id +
      "&name=" +
      param.name +
      "&trainingId=" +
      param.trainingId +
      "&positionId=" +
      param.positionId +
      "&positionId1=" +
      param.positionId1 +
      "&lessonId=" +
      param.lessonId +
      "&lessonId1=" +
      param.lessonId1 +
      "&pageSize=" +
      param.pageSize +
      "&startNum=" +
      param.startNum
  );

// 查看学习行为记录
export const getUserSchedule = (param) =>
  axios.get(
    "/course/platformTraining/getUserSchedule?userId=" +
      param.userId +
      "&lessonId=" +
      param.lessonId +
      "&lessonId1=" +
      param.lessonId1 +
      "&pageSize=" +
      param.pageSize +
      "&startNum=" +
      param.startNum +
      "&trainingId=" +
      param.trainingId
  );

// 查看单位数据
export const getCompanyLearnDataList = (param) =>
  axios.get(
    "/course/platformTraining/getCompanyLearnDataList?lessonId=" +
      param.id +
      "&companyId=" +
      param.companyId +
      "&name=" +
      param.name +
      "&positionId=" +
      param.positionId +
      "&lessonId=" +
      param.lessonId +
      "&pageSize=" +
      param.pageSize +
      "&startNum=" +
      param.startNum
  );

// 所用章设置===========
/**
 * 添加所用章
 * @param {traingId,exaimList,exaimList} param
 * traingId-培训id
 * exaimList:[{"examineSealName":"","examineSealUrl":""}]
 * trainingList [{"trainingSealName":"","trainingSealUrl":""}]
 * @returns
 */
export const saveTrainingSeal = (param) =>
  axios.post("/course/platformTrainingSeal/saveTrainingSeal", param);

// 所用章详情
export const platformTrainingSealDetail = (param) =>
  axios.get(" /course/platformTrainingSeal/detail?id=" + param.id);

// 证书获取情况===========
// 证书获取情况列表数据
/**
 *
 * @param {trainingId,status,companyId,provinceId,cityId,regionId,streetId,startNum,pageSize} param
 * trainingId:培训id
 * status:状态  0-未发放 1-已发放
 * companyId:单位id
 * provinceId:省id
 * cityId:市id
 * regionId:区id
 * streetId:街道id
 * startNum:起始页(页码)
 * pageSize:条数(每页条数)
 * @returns
 */
export const getPlatformTrainingIssueCertDataList = (param) =>
  axios.get(
    "/course/educationCertificate/getPlatformTrainingIssueCertDataList?trainingId=" +
      param.trainingId +
      "&name=" +
      param.name +
      "&status=" +
      param.status +
      "&companyId=" +
      param.companyId +
      "&provinceId=" +
      param.provinceId +
      "&cityId=" +
      param.cityId +
      "&regionId=" +
      param.regionId +
      "&streetId=" +
      param.streetId +
      "&pageSize=" +
      param.pageSize +
      "&startNum=" +
      param.startNum
  );

// 颁发证书
export const saveCertificate = (param) =>
  axios.post("/course/educationCertificate/saveCertificate", param);
export const saveCertificatess = (param) =>
  axios.get(
    "course/educationCertificate/resetCert?userId=" +
      param.userId +
      "&trainingId=" +
      param.trainingId
  );
// 获取用户证书
export const getCertByUserId = (param) =>
  axios.get(
    "/course/educationCertificate/getCertByUserId?userId=" +
      param.userId +
      "&trainingId=" +
      param.trainingId
  );
// 导出数据
export const examineExportListAll = (param) => {
  return axios({
    method: "get",
    responseType: "blob",
    url:
      "/course/educationCertificate/exportPlatformTrainingIssueCertDataList?encryptPhone=" +
      param.encryptPhone +
      "&encryptIdCard=" +
      param.encryptIdCard +
      "&trainingId=" +
      param.trainingId +
      "&name=" +
      param.name +
      "&status=" +
      param.status +
      "&companyId=" +
      param.companyId +
      "&provinceId=" +
      param.provinceId +
      "&cityId=" +
      param.cityId +
      "&regionId=" +
      param.regionId +
      "&streetId=" +
      param.streetId +
      "&ids=" +
      param.ids,
  });
};
// 导入模版
export const templateLink = () => {
  return axios({
    method: "get",
    responseType: "blob",
    url: "/course/lesson/downloadImportTrainingLessonUserTemplate",
  });
};
// 导入批量颁发模版
export const templateLinkIssue = () => {
  return axios({
    method: "get",
    responseType: "blob",
    url: "/course/platformTraining/downloadImportBatchIssueCertTemplate",
  });
};
// 导入表格
export const commonYonghua = (param) => {
  return axios({
    url: "/course/lesson/importTrainingLessonUser",
    method: "post",
    data: param,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
// 导入颁发证书表格
export const commonYonghuaIssue = (param) => {
  return axios({
    url: "/course/platformTraining/importBatchIssueCertExcel",
    method: "post",
    data: param,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
