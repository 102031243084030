// 培训机构管理员可访问路径
export function trainingInstitutionAccess(url) {
  let flag = true;
  switch (url) {
    case "/trainingInstitutionCourseList":
      flag = false;
      break;
    case "/homePage":
      flag = false;
      break;
    case "/NotFound":
      flag = false;
      break;
    case "/cannotAccess":
      flag = false;
      break;
    case "/viewStudents":
      flag = false;
      break;
    case "/login":
      flag = false;
      break;
    default:
      break;
  }
  return flag;
}
